import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import { useTransliteration } from "../providers/transliterate";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton/IconButton";

export const BackButton = () => {
    const tl = useTransliteration();
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate(-1);
    }
    
    return (
      <Tooltip title={ tl("Назад")}>
      <IconButton sx={{ mr: 2 }} onClick={goBack} color="inherit">
          <ArrowBackIcon />
      </IconButton>
      </Tooltip>
    );
  }

  export const CloseButton = () => {
    const tl = useTransliteration();
    const navigate = useNavigate();
    
    const closeTab = () => {
        window.close();
    }
    
    return (
      <Tooltip title={ tl("Затвори таб")}>
      <IconButton sx={{ mr: 2 }} onClick={closeTab} color="inherit">
          <ClearIcon />
      </IconButton>
      </Tooltip>
    );
  }