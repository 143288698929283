import { useRecoilState, useRecoilValue } from "recoil";
import { availableConfigs, currConfigId } from "../providers/app-state";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import QuizIcon from "@mui/icons-material/Quiz";
import PollIcon from '@mui/icons-material/Poll';

//import Typography from "@mui/material/Typography";
import { useTransliteration } from "../providers/transliterate";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../providers/firebase";

import "./test-listing.css";
import { findFirstActiveConfig } from "../utils/initialize";
import isSurveyOnly from "../utils/is-survey-only";


const TestListing = () => {
  const configs = useRecoilValue(availableConfigs);
  const [cfgId, setConfigId] = useRecoilState(currConfigId);
  const tl = useTransliteration();

  const [user] = useAuthState(firebaseAuth);

  const [selectedId, setSelectedId] = useState(cfgId);

  const handleListItemClick = (id: string) => {
    setSelectedId(id);
    setConfigId(id);
  };

  useEffect(() => {
    if (cfgId === "") {
      setSelectedId( findFirstActiveConfig(configs).id );  // fallback to 1st if no selection
    } else {
      setSelectedId(cfgId);
    }
    setConfigId(cfgId);
  }, [cfgId, setConfigId, configs]);

  const shouldShow = (active: number) => {
    if (user) return true;
    return active !== 0;
  };

  return (
    <List sx={{ width: "100%" }}>
      {configs.map((item) => {
        const {id, active, cfg} = item;
        return shouldShow(active) ? (
          <ListItem
            button
            key={id}
            selected={selectedId === id}
            onClick={() => handleListItemClick(id)}
            secondaryAction={
              <Chip
                label={item.count}
                color="primary"
                variant={selectedId === id ? "filled" : "outlined"}
              />
            }
          >
            <ListItemIcon>
              {selectedId === id ? (
                isSurveyOnly(cfg) ? <PollIcon color="primary" /> : <QuizIcon color="primary" />
              ) : (
                isSurveyOnly(cfg) ? <PollIcon color="disabled" /> : <QuizIcon color="disabled" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={tl(item.name)}
              primaryTypographyProps={{
                className: item.active ? "" : "inactiveItem",
              }}
              secondary={tl(item.description)}
              secondaryTypographyProps={{
                className: item.active ? "" : "inactiveItem",
              }}
            />
          </ListItem>
        ) : null;
      })}
    </List>
    // </Box>
  );
};

export default TestListing;
