import { atom, AtomEffect, DefaultValue, selector } from "recoil";
import { getAverageForFilter } from "./iat-logs";
import { IATConfig, IATLogEntry, IATResponse, IAveragesForFilterResponse, IFilterProps } from "../iat-types";
import { findFirstActiveConfig, getAgeRanges, getAvailableConfigs } from "../utils/initialize";
import { isMobileDevice } from "../utils/is-touch";
import { hydrateConfig } from "../utils/load-images";
import { getUsedCountries } from "./iat-logs";
import { HashtagCategories } from "../utils/hashtags";
import { findConfigById } from "../utils/initialize";

import {Model, SurveyModel} from 'survey-core';
import { extractQuestionValuesAndLabels } from "../survey/validate-response-fields";
import { appScript } from "./transliterate";
import { BASIC_SURVEY_DEF } from "../constants";
import { fetchText } from "./fetch";

export interface IAppPrefs {
  language: string;
}


const sessionStorageEffect: <T=any>(key: string) => AtomEffect<T> = (
  key: string
) => ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    if (isReset || newValue instanceof DefaultValue) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    }
  });
};

const __debugInterval = atom({
  key: "__debugInterval",
  default: -1, // -1 == no debugging. Otherwise, ms interval for card swiping.
});

const __isDebugMode = selector({
  key: "__isDebugMode",
  get: ({ get }) => {
    const interval = get(__debugInterval);
    return interval > 0;
  },
});

const appPrefs = atom<IAppPrefs | null>({
  key: "appPrefs", // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

const availableConfigs = atom({
  key: "availableConfigs",
  default: getAvailableConfigs(),
  // effects: [sessionStorageEffect("availableConfigs")]  // ne radi za async/Promise ?
});

const configNames = selector<string[]>({
  key: "configNames",
  get: ({ get }) => {
    const configs = get(availableConfigs);
    return configs.map((c) => c.id);
  },
});
const currConfigId = atom<string>({
  key: "currConfigId", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  effects: [
    sessionStorageEffect("currConfigId")
  ]
});

const currConfigObject = selector<IATConfig>({
  key: "currConfigObject",
  get: ({ get }) => {
    const id = get(currConfigId);
    const configs = get(availableConfigs);
    const c = findConfigById(id, configs);
    if (!c){
      // Fallback to 1st test. Alerts if no active test exist.
      return hydrateConfig(findFirstActiveConfig(configs));
    }
    return hydrateConfig(c);
  },
});

const currSurveyModel = selector<SurveyModel>({
  key: "currSurveyModel",
  get: async ({ get }) => {
    const cfg = get(currConfigObject);
    if (cfg.surveyDefinition){
      if (typeof cfg.surveyDefinition === 'string'){
        const survey = JSON.parse(cfg.surveyDefinition);
        return survey;
      }else{
        return cfg.surveyDefinition;
      }
    }else{
      console.warn(`${cfg.id} ne sadrži definiciju ankete - biće korišćena podrazumevana anketa.`)
      const txt = await fetchText(BASIC_SURVEY_DEF);
      return JSON.parse(txt);
    }

  },
});

const surveyMainOptions = selector({
  key: "surveyMainOptions",
  get: ({ get }) => {
    const model = get(currSurveyModel);
    if (!model) return null;
    const survey = new Model(model);
    const question = survey.getQuestionByName("surveyMain"); 
    if (!question) return null;

    const loc = get(appScript)
    return {
      title: question.getLocalizableString("title")?.getLocaleText(loc),
      options: extractQuestionValuesAndLabels(question, loc)
    }
  },
});

const currAnswers = atom<IATResponse[]>({
  key: "currAnswers",
  default: [],
  effects: [sessionStorageEffect("currAnswers")]
});

const logEntry = atom<IATLogEntry>({
  key: "logEntry",
  default: {
    yob: new Date().getFullYear() - 18,
    date: new Date().getTime(),
    country: "XX",
    citySize: "l",
    d_score: NaN,
    deviceType: isMobileDevice() ? 1 : 2,
    gender: "male",
    edu: 3,
    finance: 4,
    art: NaN,
  },
  effects: [sessionStorageEffect("logEntry")]
});

// for results, filtering etc.

const ageGroups = atom({
  key: "ageGroups",
  default: getAgeRanges(),
  // effects: [sessionStorageEffect("ageGroups")] // ne radi za async/Promise ?
});

const currUsedCountries = selector({
  key: "currUsedCountries",
  get: ({ get }) => {
    const config = get(currConfigObject);
    return getUsedCountries(config.id);
  },
});

export const emptyFilter:IFilterProps = {
  country: "",
  citySize: "",
  gender: "",
  edu: "",
  finance: "",
  ageGroupId: "",
  surveyMain: ""
};

const currFilter = atom<IFilterProps>({
  key: "currFilter",
  default: {...emptyFilter},
});

const currStats = selector<IAveragesForFilterResponse>({
  key: "currGroup",
  get: ({ get }) => {
    const f = get(currFilter);
    const id = get(currConfigObject).id;
    return getAverageForFilter(id, f);
  },
  });

  const userSubmitted = atom<boolean>({
    key: "userSubmitted",
    default: false,
    effects: [sessionStorageEffect("userSubmitted")]
  });

// const currReplacer = selector<IReplacer>({
//   key: "currReplacer",
//   get: ({ get }) => {
//     const id = get(currConfigObject).id;
//     const countries = get(currUsedCountries);
//     return {};
//   },
// });

const hashtagMap = atom<HashtagCategories>({
  key: "hashtagMap",
  default: {} // new Map<string,string>([])
});

export {
  appPrefs,
  currConfigId,
  currConfigObject,
  availableConfigs,
  configNames,
  logEntry,
  currAnswers,
  ageGroups,
  currUsedCountries,
  currSurveyModel,
  surveyMainOptions,
  currFilter,
  currStats,
  userSubmitted,
  __debugInterval,
  __isDebugMode,
  hashtagMap,
};
