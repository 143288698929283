import { IATConfig, IATCategoryNames, IATCategories, IATCategorySet, IGender } from "../iat-types";
import { genderify } from "./genderify";

export type HashtagMap = {
    map: Map<string, string>;
    gender?: boolean | IGender;
};

export type HashtagCategories = {
    [k in IATCategories]?: HashtagMap;
}

export const isHashtagItem = (item:string) =>  item.startsWith('#');

export const countHashtags = (cfg: IATConfig) => {
    let count = 0;

    if (cfg.surveyOnly) return 0;
    
    for(let c of IATCategoryNames){
        count += cfg.categories[c].items.filter(item => isHashtagItem(item) ).length;
    }
    return count;
}

export const hasHashtags = (cfg: IATConfig) => countHashtags(cfg) > 0;

export const isHashmapEmpty = (hash:HashtagCategories):boolean => {
    for(let c of IATCategoryNames){
        if (hash[c]?.map.size || Array.from(hash[c]?.map.values() || [""]).some(x=> x.trim().length === 0)) return false;
    }
    return true;
}

export const resetHashtagMap = (hashtagMap: HashtagMap) => {hashtagMap.map.clear(); hashtagMap.gender = undefined}

export const buildHashtagMap = (cfg: IATConfig ) => {

    //const hashtagMap:HashtagMap = new Map<string, string>([]);
    const hashtagMap: HashtagCategories = {}

    //hashtagMap.clear();
    for(let c of IATCategoryNames){
        cfg.categories[c].items.forEach(item=>{
            if ( isHashtagItem(item) ){
                if (hashtagMap[c] === undefined){
                    let g:IGender | undefined = undefined;
                    const configGender = cfg.categories[c].gender;
                    if (configGender !== undefined){
                        g = configGender === "female" ? "female" : "male";
                    } 
                    hashtagMap[c] = {
                        map: new Map<string,string>([]),
                        gender: g
                    }
                }
                hashtagMap[c]?.map.set(item, "" /*item.substring(1)*/);
            }
        })
    }
    return hashtagMap;
    
};

export const replaceHashtags = (cfg: IATConfig, values: HashtagCategories)  => {
    
    const numTags = countHashtags(cfg);
    let res  = JSON.parse(JSON.stringify(cfg.categories)) as IATCategorySet// {...cfg};
    
    if (numTags === 0) return cfg.categories;

    for(let c of IATCategoryNames){
        const hash = values[c];
        const cat = res[c];
        res[c].items = cat.items.map(item => hash?.map.get(item) || item);
        if (values[c]?.gender){
            res[c].items = cat.items.map(item => genderify(item, values[c]?.gender));
        }
    }

    return res;
}

